import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { kebabCase } from 'lodash'
import Img from 'gatsby-image'

class PicturesList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      posts &&
        posts.map(({ node: post }) => (
          <article className='post hentry' key={post.id} data-id={post.id}>
            <div className='uk-card uk-card-hover uk-card-default'>
              {post.frontmatter.featuredimage ? (
                <div
                  className='post_thumbnail uk-card-media-top uk-text-center uk-position-relative uk-transition-toggle'
                  data-title={post.frontmatter.title}>
                  <Link to={post.fields.slug}>
                    <Img
                      fixed={post.frontmatter.featuredimage.childImageSharp.fixed}
                      alt={`náhled pro '${post.title}'`}
                    />
                    <div className='uk-position-cover uk-transition-fade uk-overlay-primary'></div>
                    <div className='uk-position-center'>
                          <span className='uk-transition-fade'
                                data-uk-icon='icon:image;ratio:2.3'></span>
                    </div>
                  </Link>
                </div>
              ) : null}
              <div className='post_content uk-card-small uk-card-body'>
                {post.frontmatter.tags && post.frontmatter.tags.length ? (
                  <div className='post_labels uk-panel uk-margin-small'>
                    <span className='uk-margin-small-right' data-uk-icon='icon:tag;ratio:.7'>
                      <svg width="15"
                           height="15"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           data-svg="tag"><path
                        fill="none" stroke="#000" stroke-width="1.1"
                        d="M17.5,3.71 L17.5,7.72 C17.5,7.96 17.4,8.2 17.21,8.39 L8.39,17.2 C7.99,17.6 7.33,17.6 6.93,17.2 L2.8,13.07 C2.4,12.67 2.4,12.01 2.8,11.61 L11.61,2.8 C11.81,2.6 12.08,2.5 12.34,2.5 L16.19,2.5 C16.52,2.5 16.86,2.63 17.11,2.88 C17.35,3.11 17.48,3.4 17.5,3.71 L17.5,3.71 Z"></path><circle
                        cx="14" cy="6" r="1"></circle></svg>
                    </span>
                    {post.frontmatter.tags.map((tag, index) => (
                      <>
                        <Link to={`/tags/${kebabCase(tag)}/`} rel="tag" key={tag + `TahakTag`}>#{tag}</Link>
                        {index < (post.frontmatter.tags.length - 1) && <span className='uk-margin-xsmall-right'>,</span>}
                      </>
                    ))}
                  </div>
                ) : null}
                <h2 className='post_title entry-title uk-margin'>
                  <Link
                    rel='bookmark'
                    to={post.fields.slug}
                    title={`Obrázek ${post.frontmatter.title}`}
                  >
                    {post.frontmatter.title.length > 30
                      ? `${post.frontmatter.title.substr(0, 30)}...`
                      : post.frontmatter.title}
                  </Link>
                </h2>
              </div>
            </div>
          </article>
        ))
    )
  }
}

PicturesList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PicturesList;
